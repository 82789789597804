'use strict';

/**
 * Extract billing address of corresponding PayPal payment account from paypal account option
 * @param {Object} $selectedPayPalPayment PayPal payment option
 * @returns {string} return string representation of extracted address
 */
function getBillingAddressAsString($selectedPayPalPayment) {
    var selectedBillingAddressStr = $selectedPayPalPayment.getAttribute('data-billing-address');
    var selectedBillingAddressObj;
    var billingAddressRepresentationString = '';

    if (selectedBillingAddressStr && selectedBillingAddressStr !== '') {
        selectedBillingAddressObj = JSON.parse(selectedBillingAddressStr);

        if (selectedBillingAddressObj && selectedBillingAddressStr !== '{}') {
            var firstName = selectedBillingAddressObj.firstName || '';
            var lastname = selectedBillingAddressObj.lastName || '';
            var address1 = selectedBillingAddressObj.line1 || selectedBillingAddressObj.address1 || '';
            var city = selectedBillingAddressObj.city || '';
            var state = selectedBillingAddressObj.state || selectedBillingAddressObj.stateCode || '';
            var postalCode = decodeURIComponent(selectedBillingAddressObj.postalCode) || '';

            billingAddressRepresentationString = firstName + ' ' +
            lastname + ' ' +
            address1 + ' ' +
            city + ', ' +
            state + ' ' +
            postalCode;
        }
    }

    return billingAddressRepresentationString;
}

/**
 * Display billing address of passed paypal payment option (of selected paypal payment option)
 * @param {Object} $selectedPayPalPayment Selected PayPal payment option
 */
function displaySelectedStoredBillingAddress($selectedPayPalPayment) {
    if ($selectedPayPalPayment) {
        var selectedOptionClassList = $selectedPayPalPayment.classList;

        if (selectedOptionClassList.contains('js-stored-paypal-account') || selectedOptionClassList.contains('js-session-paypal-account')) {
            var billingAddressAsString = getBillingAddressAsString($selectedPayPalPayment);
            var $storedPayPalAddressOption = $('#billingAddressSelector option[class="stored-paypal-address"]');
            var storedPayPalAddressOptionExists = $storedPayPalAddressOption.length;

            if (billingAddressAsString !== '') {
                if (storedPayPalAddressOptionExists) {
                    $storedPayPalAddressOption.text(billingAddressAsString);
                    $storedPayPalAddressOption.prop('selected', true);
                } else {
                    $('#billingAddressSelector').append('<option class="stored-paypal-address" selected>' + billingAddressAsString + '</option>');
                }
            }
        }
    }
}

/**
 * Returns the matching address ID or UUID for a billing address
 * @returns {string} Matching address ID
 */
function getMatchingBillingAddressId() {
    var matchingBillingAddressId;

    $.ajax({
        url: document.querySelector('.js_braintree_getOrderInfoUrl').value + '?qwe=2344',
        type: 'get',
        async: false,
        dataType: 'json',
        success: function (data) {
            if (data.matchingBillingAddressId) {
                matchingBillingAddressId = data.matchingBillingAddressId;
            } else {
                matchingBillingAddressId = false;
            }
        },
        error: function () {
            window.location.reload();
        }
    });

    return matchingBillingAddressId;
}

/**
 * Set default billing address as selected
 */
function selectDefaultBillingAddressOption() {
    var billingAccountsSelector = '#billingAddressSelector';
    var $braintreePaypalAccountList = document.querySelector('#braintreePaypalAccountsList');
    var matchingBillingAddressId;

    if ($braintreePaypalAccountList) {
        matchingBillingAddressId = getMatchingBillingAddressId();

        if (matchingBillingAddressId && matchingBillingAddressId !== '') {
            $(`${billingAccountsSelector} option[value="${matchingBillingAddressId}"]`).prop('selected', true);
        } else {
            // Shows billing address of session account
            $(`${billingAccountsSelector} option[value="${'manual-entry'}"]`).prop('selected', true);
        }

        $(`${billingAccountsSelector} option[class="stored-paypal-address"]`).remove();
    }
}

module.exports = {
    displaySelectedStoredBillingAddress,
    selectDefaultBillingAddressOption
};
