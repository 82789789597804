'use strict';

var ErrorHandlingBaseModel = require('./errorHandlingBaseModel');

/**
 * Error Handling Cart model constructor
 * @param {Object} errorMessages object with error messages
 */
function ErrorHandlingCartModel(errorMessages) {
    ErrorHandlingBaseModel.call(this, errorMessages);

    this.errorMessages = errorMessages;
}

/**
 * ES5 inheritance
 */
ErrorHandlingCartModel.prototype = Object.create(ErrorHandlingBaseModel.prototype);

// See corresponding method inside "ErrorHandlingBaseModel" model
ErrorHandlingCartModel.prototype.createErrorNotification = function (message) {
    var $errorContainer = $('.cart-error-messaging');
    $errorContainer.append(
        `<div class="alert alert-danger alert-dismissible valid-cart-error fade show cartError custom-cart-error" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            ${message}
        </div>`
    );

    $errorContainer.show();
    window.scrollTo(0, 0);
};

// Shows an error when the value of the total amount on the Card is 0
ErrorHandlingCartModel.prototype.showZeroAmountOnCart = function () {
    ErrorHandlingCartModel.prototype.createErrorNotification(this.errorMessages.CUSTOM_SRC_ZERO_AMOUNT_ERROR);
};

// See corresponding method inside "ErrorHandlingBaseModel" model
ErrorHandlingCartModel.prototype.hideError = function () {
    var $errorContainer = $('.cart-error-messaging');

    $errorContainer.hide();
    $errorContainer.empty();
};

/**
 * Shows cart error in core error container
 * @param {Object} data error data
 */
ErrorHandlingCartModel.prototype.handleCheckoutFromCartError = function (data) {
    var errorMessage = '';

    if (data.fieldErrors.length) {
        data.fieldErrors.forEach(function (error, index) {
            var keys = Object.keys(error);

            if (keys.length) {
                errorMessage += `${keys[index].replace('dwfrm_billing_', '').replace('_', ' ')} ${data.fieldErrors[index][keys[index]]}. `;
            }
        });

        this.showErrorByMessage(errorMessage);
    }

    if (data.serverErrors.length) {
        data.serverErrors.forEach(function (error) {
            errorMessage += `${error}. `;
        });

        this.showErrorByMessage(errorMessage);
    }

    if (data.cartError) {
        window.location.href = data.redirectUrl;
    }
};

module.exports = ErrorHandlingCartModel;
