'use strict';

/* global paypal braintree $ */

/**
 * Function creates PayPal Checkout instance
 * @param {Promise} btClientInstancePromise BT Client Instance Promise
 * @param {Object} payPalPaymentProcessingModal Instance of PayPalPaymentProcessing model. Is needed to have customize CheckoutInstance generation
 * depending on PayPalPaymentProcessing instance congfigs
 * @returns {Promise} Promise with PayPal Checkout Instance
 */
function createPayPalCheckoutInstance(btClientInstancePromise, payPalPaymentProcessingModal) {
    return btClientInstancePromise
        .then(function (btClientInstance) {
            var payPalCheckoutConfigs = payPalPaymentProcessingModal.payPalCheckoutInstanceConfigs(btClientInstance);

            return braintree.paypalCheckout.create(payPalCheckoutConfigs);
        });
}

/**
 * Creates PayPal button style configs
 * @param {Object} btPayPalConfigurations Configurations of PayPal button
 * @returns {Object} Style configs with the right format
 */
function createPayPalStyleConfigurations(btPayPalConfigurations) {
    var payPalButtonStyleConfigs = btPayPalConfigurations.paypalConfig.style;

    return {
        height: payPalButtonStyleConfigs.height,
        color: payPalButtonStyleConfigs.color,
        shape: payPalButtonStyleConfigs.shape,
        layout: payPalButtonStyleConfigs.layout,
        label: payPalButtonStyleConfigs.label,
        tagline: payPalButtonStyleConfigs.tagline
    };
}

/**
 * Callback which is came from PayPal configuratinos. Should trigger only "enable" action
 * @param {Object} validateActions Object from Braintree
 */
var payPalFormValidationConrol = function (validateActions) {
    var isFormValid = true;

    if (isFormValid) {
        validateActions.enable();
    } else {
        validateActions.disable();
    }
};

/**
 * Returns PayPal button behavior configs which are used when VaultMode is enabled
 * @param {Promise} btPayPalCheckoutInstance Promise with PayPal Checkout Instance
 * @param {Object} btPayPalConfigurations Object with PayPal configurations
 * @param {Object} payPalPaymentProcessingModal Instance of PayPalPaymentProcessing model
 * @returns {Object} Object with PayPal button configs & callbacks wich will handle PayPal button behavior
 */
function getVaultModeButtonConfigurations(
    btPayPalCheckoutInstance,
    btPayPalConfigurations,
    payPalPaymentProcessingModal
) {
    var payPalButtonStyleConfigs = createPayPalStyleConfigurations(btPayPalConfigurations);
    var vaultModePayPalButtonConfigs = {
        onClick: function (_, actions) {
            return payPalPaymentProcessingModal.onPayPalButtonClickCallback(_, actions);
        },
        createBillingAgreement: function () {
            // Logic which executed after buyer clicked on the button, and PayPal modal start loading
            // "onOrderCreateCallback" is used for forming of order data object
            var paymentObject = payPalPaymentProcessingModal.onOrderCreateCallback();

            return btPayPalCheckoutInstance.createPayment(paymentObject);
        },
        onApprove: function (data) {
            // Some logic here before tokenization happens below
            return btPayPalCheckoutInstance.tokenizePayment(data)
                .then(function (payload) {
                    payPalPaymentProcessingModal.onApprovePaymentCallback(payload);
                });
        },
        onCancel: function () {
            payPalPaymentProcessingModal.onCancelPaymentCallback();
        },

        onError: function (err) {
            payPalPaymentProcessingModal.onErrorPaymentCallback(err);
        },
        style: payPalButtonStyleConfigs,
        validate: function (validateActions) {
            payPalFormValidationConrol(validateActions);
        }
    };

    return vaultModePayPalButtonConfigs;
}

/**
 * Returns PayPal button behavior configs which are used when VaultMode is disabled or "Checkout with Vault" is enabled
 * @param {Promise} btPayPalCheckoutInstance Promise with PayPal Checkout Instance
 * @param {Object} btPayPalConfigurations Object with PayPal configurations
 * @param {Object} payPalPaymentProcessingModal Instance of PayPalPaymentProcessing model
 * @returns {Object} Object with PayPal button configs & callbacks wich will handle PayPal button behavior
 */
function getCheckoutModeButtonConfigurations(
    btPayPalCheckoutInstance,
    btPayPalConfigurations,
    payPalPaymentProcessingModal
) {
    var payPalButtonStyleConfigs = createPayPalStyleConfigurations(btPayPalConfigurations);
    var vaultModePayPalButtonConfigs = {
        onClick: function (_, actions) {
            return payPalPaymentProcessingModal.onPayPalButtonClickCallback(_, actions);
        },
        createOrder: function () {
            // Logic which executed after buyer clicked on the button, and PayPal modal start loading
            // "onOrderCreateCallback" is used for forming of order data object
            var paymentObject = payPalPaymentProcessingModal.onOrderCreateCallback();

            return btPayPalCheckoutInstance.createPayment(paymentObject);
        },
        onApprove: function (data) {
            // Some logic here before tokenization happens below
            return btPayPalCheckoutInstance.tokenizePayment(data)
                .then(function (payload) {
                    payPalPaymentProcessingModal.onApprovePaymentCallback(payload);
                });
        },
        onCancel: function () {
            payPalPaymentProcessingModal.onCancelPaymentCallback();
        },

        onError: function (err) {
            payPalPaymentProcessingModal.onErrorPaymentCallback(err);
        },
        style: payPalButtonStyleConfigs,
        validate: function (validateActions) {
            payPalFormValidationConrol(validateActions);
        }
    };

    return vaultModePayPalButtonConfigs;
}

/**
 * Function which result is rendered PayPal button
 * @param {Promise} btClientInstancePromise Client instance promise
 * @param {boolean} isVaultMode "true" in case if VaultMode is enabled
 * @param {Object} btPayPalConfigurations Object with PayPal configurations
 * @param {string} payPalButtonSelector PayPal button selector (container inside of which PayPal button will be rendered)
 * @param {Object} payPalPaymentProcessingModal instance of PayPalPaymentProcessing model
 * @returns {Promise} PayPal button
 */
function payPalButtonRendering(
    btClientInstancePromise,
    isVaultMode,
    btPayPalConfigurations,
    payPalButtonSelector,
    payPalPaymentProcessingModal
) {
    var btPayPalCheckoutInstancePromise = createPayPalCheckoutInstance(btClientInstancePromise, payPalPaymentProcessingModal);

    var paypalButtonConfigs = null;
    var paypalButtonSdkUrlConfigs = null;

    var changePMButtonEnabled = payPalPaymentProcessingModal.changePMButtonEnabled;

    return btPayPalCheckoutInstancePromise
        .then(function (btPayPalCheckoutInstance) {
            // Change Paymnet Method button flow (checkout with vault flow)
            if (changePMButtonEnabled) {
                paypalButtonConfigs = getCheckoutModeButtonConfigurations(
                    btPayPalCheckoutInstance,
                    btPayPalConfigurations,
                    payPalPaymentProcessingModal
                );
                paypalButtonSdkUrlConfigs = payPalPaymentProcessingModal.payPalCheckoutSdkConfigs();
            // If vault mode
            } else if (isVaultMode) {
                paypalButtonConfigs = getVaultModeButtonConfigurations(
                    btPayPalCheckoutInstance,
                    btPayPalConfigurations,
                    payPalPaymentProcessingModal
                );
                paypalButtonSdkUrlConfigs = payPalPaymentProcessingModal.payPalVaultSdkConfigs();
                // If checkout mode
            } else {
                paypalButtonConfigs = getCheckoutModeButtonConfigurations(
                    btPayPalCheckoutInstance,
                    btPayPalConfigurations,
                    payPalPaymentProcessingModal
                );
                paypalButtonSdkUrlConfigs = payPalPaymentProcessingModal.payPalCheckoutSdkConfigs();
            }

            // PayPal button loading
            if (!window.isPayPalSDKLoaded) {
                // We pass params to customize PayPal SDK loading.
                btPayPalCheckoutInstance.loadPayPalSDK(paypalButtonSdkUrlConfigs).then(function () {
                    window.isPayPalSDKLoaded = true;
                    // eslint-disable-next-line new-cap
                    return paypal.Buttons(paypalButtonConfigs).render(payPalButtonSelector);
                });
            } else {
                // In case if PayPal SDK was already loaded (with custom parameters), we can simple load PP button
                // eslint-disable-next-line new-cap
                return paypal.Buttons(paypalButtonConfigs).render(payPalButtonSelector);
            }
        });
}

module.exports = {
    payPalButtonRendering
};
