/* eslint-disable no-console */
'use strict';

/* global Promise braintree $ */

var helper = require('../../helper');
var creditCardFormFieldHelper = require('../helpers/creditCardFormFieldsHelper');
var btCreditCardModel = require('../braintreesdk/braintreeCreditCardModel');
var creditCardPaymentProcessingHelper = require('../helpers/creditCardPaymentProcessingHelper');
var loaderInstance = require('../../loaderHelper');
var creditCardPaymentProcessingConstants = require('../constants/creditCardPaymentProcessingConstants');
var creditCardVaultManager = require('./creditCardVaultManager');
var creditCardHelper = require('../helpers/creditCardHelper');

var $continueButton = document.querySelector('button.submit-payment');
var $creditCardList = document.querySelector('#braintreeCreditCardList');
var $braintreeCreditCardLoader = document.querySelector('#braintreeCreditCardLoader');
var $customCreditCardErrorContainer = document.querySelector('#customCreditCardErrorContainer');

var loader;
var errHandlingModel;

/**
 * Process Credit Card on the Billing Page
 * @param {Object} event Event object
 * @returns {void}
 */
function processCreditCard(event) {
    event.preventDefault();
    event.stopPropagation();

    // Removes error messages on the Billing Page
    errHandlingModel.hideError();

    try {
        var customErrorMessages = JSON.parse($customCreditCardErrorContainer.getAttribute('data-errors'));
        var basketDataValidResponse = btCreditCardModel.isBasketDataValid(customErrorMessages.TOTAL_BASKET_AMOUNT_INVALID);
        var clientInstancePromise = btCreditCardModel.getClientInstancePromise();

        if (basketDataValidResponse.error) {
            throw basketDataValidResponse.customErrorMessage;
        }

        // Data passed inside "braintreeCreditCardModel"
        var billingData = helper.getBillingAddressFormValues();
        var email = document.querySelector('.customer-summary-email').textContent;
        var isCheckoutFlow = true;

        var creditCardFlow = creditCardPaymentProcessingHelper.getCreditCardFlowID($creditCardList);
        var result;

        loader.show();

        switch (creditCardFlow) {
            // Case for new card
            case creditCardPaymentProcessingConstants.FLOW_NEW_CARD_NAME:
                result = btCreditCardModel.processNewCard(email, billingData, isCheckoutFlow)
                    .then(function (btPayload) {
                        var btPayloadDetails = btPayload.btTokenizePayload.details;
                        var newCardType = btPayloadDetails.cardType.toLowerCase();
                        var newCardLastFour = btPayloadDetails.lastFour.toString();

                        // Hide already stored card in case if new card is the same
                        creditCardVaultManager.isNewCardCanBeStored(
                            clientInstancePromise,
                            newCardLastFour,
                            newCardType
                        )
                            .then(function (newCardCanBeStored) {
                                if (!newCardCanBeStored) {
                                    creditCardPaymentProcessingHelper.hideAlreadyStoredCard(newCardLastFour, newCardType);
                                    // Hide CC save block and unclick "save" checkbox
                                    creditCardHelper.hideSaveCreditCardBlock(true);
                                    creditCardHelper.saveCreditCard(false);
                                }
                            });

                        // Fill hidden inputs CreditCard fields and Session CreditCard div fields
                        creditCardPaymentProcessingHelper.fillCreditCardFormWithPayloadData(btPayload);

                        // Filling of session card attributes for using them in business logic (client/server)
                        creditCardPaymentProcessingHelper.setSessionCreditCardDataAttributes(btPayload);

                        // Fill 'toDisplay' fields with data to display them for buyer
                        creditCardPaymentProcessingHelper.fillSessionCreditCardFormWithPayloadData(btPayload);

                        // Display "Stored Accounts" list
                        creditCardPaymentProcessingHelper.displayStoredCreditCardList();

                        // Select "Session Account Option" in "Account List"
                        creditCardPaymentProcessingHelper.markAsSelectedSessionAccount();

                        // Clear "Hosted Fields" (from iframe)
                        btCreditCardModel.clearHostedFields();

                        // Show "Session Credit Card" fields (in div blocks)
                        creditCardFormFieldHelper.showCardElements(creditCardFormFieldHelper.getCCFieldsToDisplay().asArray);

                        // Hide hosted fields (iframe fields)
                        creditCardFormFieldHelper.hideCardElements(creditCardFormFieldHelper.getCCFields().asArray);
                        helper.continueButtonToggle(false);
                    });
                break;

            // Case for session card
            case creditCardPaymentProcessingConstants.FLOW__SESSION_CARD_NAME:
                result = btCreditCardModel.processSessionCard();
                break;

            // Case for stored card
            case creditCardPaymentProcessingConstants.FLOW_JS_STORED_CARD_NAME:
                result = creditCardPaymentProcessingHelper.getNonceFromStoredCard(clientInstancePromise, $creditCardList)
                    .then(function (response) {
                        var nonce = response.nonce;
                        var bin = response.details ? response.details.bin : '';

                        return btCreditCardModel.processStoredCard(email, billingData, nonce, bin)
                            .then(function (btStoredCardPayload) {
                                var threeDSecureDataValidationPayload = btStoredCardPayload.threeDSecureInfo;

                                if (threeDSecureDataValidationPayload) {
                                    creditCardPaymentProcessingHelper.fill3DsData(threeDSecureDataValidationPayload);
                                }
                            });
                    });
                break;

            default:
                // Handle error with wrong checkout flow
                result = Promise.reject(customErrorMessages.WROND_CHECKOUT_FLOW);
                break;
        }

        result
            .then(function () {
                loader.hide();
                // Trigger "Submit Payment" button
                event.target.click();
            })
            .catch(function (error) {
                loader.hide();

                // Braintree Errors
                errHandlingModel.creditCardErrorHandler(error);
            });
    } catch (error) {
        loader.hide();
        errHandlingModel.creditCardErrorHandler(error);
    }
}

/**
 * Inits Credit Card process on the Billing Page
 * @param {Constructor} errorHandlingModel Error handling constructor
 */
function init(errorHandlingModel) {
    loader = loaderInstance($braintreeCreditCardLoader);
    errHandlingModel = errorHandlingModel;

    $continueButton.addEventListener('click', function (event) {
        var isCreditCardTabActive = creditCardPaymentProcessingHelper.isActiveCreditCardTab();

        // Removes active session payment method once 'Next: Place order' button clicked
        helper.removeActiveSessionPayment();

        if (!event.isTrusted || !isCreditCardTabActive) {
            return;
        }

        processCreditCard(event);
    });
}

module.exports = {
    init
};
