'use strict';

/* global Promise $ */

var braintreePayPalSDK = require('../braintreesdk/braintreePayPalSDK');
var braintreeGeneral = require('../../braintreeGeneral');
var requestsHelper = require('../helpers/payPalRequestsHelperGlobal');

var loaderInstance = require('../../loaderHelper');

/**
 * PayPal base model constructor
 * @param {Object} errorHandlingModel error handling model
 * @param {Promise} btClientInstancePromise BT client instance promise
 * @param {string} payPalButtonSelector PayPal button selector
 * @param {Object} payPalConfigurations configs from server (taken from data attr)
 * @param {boolean} isVaultMode "true" in case if vault mode is enabled
 * @param {boolean} requestBillingAgreement "true" in case if billing agreement should be requested
 * @param {boolean} isFraudToolsEnabled "true" in case if fraud tools is enabled
 * @param {Object} $loaderContainer loader js container
 */
function PayPalBaseModel(
    errorHandlingModel,
    btClientInstancePromise,
    payPalButtonSelector,
    payPalConfigurations,
    isVaultMode,
    requestBillingAgreement,
    isFraudToolsEnabled,
    $loaderContainer
) {
    this.isVaultMode = isVaultMode;
    this.requestBillingAgreement = requestBillingAgreement && isVaultMode;
    this.btClientInstancePromise = btClientInstancePromise;
    this.payPalButtonSelector = payPalButtonSelector;
    this.payPalConfigurations = payPalConfigurations;
    // Fraud data related
    this.isFraudToolsEnabled = isFraudToolsEnabled;
    // Setup init fraudData value for default case (default case: fraud data disabled)
    this.fraudDataPromise = Promise.resolve({ fraudDataCollectionDisabled: true });

    // URLs
    this.basketDataUrl = payPalConfigurations.getOrderInfoUrl;
    this.submitPaymentUrl = payPalConfigurations.paypalHandle;
    this.placeOrderStageUrl = payPalConfigurations.redirectUrl;

    this.braintreePayPalSDK = braintreePayPalSDK.payPalButtonRendering;

    this.errorHandlingModel = errorHandlingModel;
    this.basketData = null;
    this.loader = loaderInstance($loaderContainer);
}

/* -------------------- Payment Callbacks -------------------- */

/**
 * Method triggers when buyer approved payment in PayPal modal and they passed succesfully
 * THIS METHOD NEEDS TO BE OVERRIDDEN in children class
 * @param {Object} payload buyer's data from PayPal
 * @returns {*} finish function execution
 */
PayPalBaseModel.prototype.onApprovePaymentCallback = function (payload) {
    // basic payload validation
    var ppDetails = payload.details;

    if (!ppDetails.billingAddress) {
        var ppShippingAddress = ppDetails.shippingAddress;
        try {
            ppDetails.billingAddress = {
                city: ppShippingAddress.city,
                countryCode: ppShippingAddress.countryCode,
                line1: ppShippingAddress.line1,
                line2: ppShippingAddress.line2 || '',
                postalCode: ppShippingAddress.postalCode,
                state: ppShippingAddress.state
            };

            return {
                error: false
            };
        } catch (err) {
            if (err) {
                this.errorHandlingModel.showBillingAddressIsNotSupported();

                return {
                    error: true
                };
            }
        }
    }
};

/**
 * Method triggers when buyer canceled payment in PayPal modal
 * THIS METHOD CAN BE OVERRIDDEN in children class
 */
PayPalBaseModel.prototype.onCancelPaymentCallback = function () {};

/**
 * Method triggers when buyer clicked on PayPal button and PayPal modal is opening
 * THIS METHOD CAN BE OVERRIDDEN in children class
 * @returns {Object} Amount and Shipping address from basket
 */
PayPalBaseModel.prototype.onOrderCreateCallback = function () {
    // Hide custom error on buyer PayPal button click.
    this.errorHandlingModel.hideError();

    // Get basket again in case if "onPayPalButtonClickCallback" wasn't triggered (Change Payment Method Button case)
    var basketData = this.basketData || requestsHelper.getBasketData(this.basketDataUrl);
    var shippingAddress = basketData.shippingAddress || this.savedPaypalAddress;

    var payPalConfigurations = this.payPalConfigurations;
    var mainPayPalConfigurations = payPalConfigurations.options;
    var requestBillingAgreement = mainPayPalConfigurations.requestBillingAgreement;

    var paymentObjectData = {
        amount: basketData.amount,
        currency: mainPayPalConfigurations.currency,
        flow: mainPayPalConfigurations.flow,
        intent: mainPayPalConfigurations.intent,
        locale: mainPayPalConfigurations.locale,
        enableShippingAddress: mainPayPalConfigurations.enableShippingAddress,
        billingAgreementDescription: mainPayPalConfigurations.billingAgreementDescription,
        displayName: mainPayPalConfigurations.displayName
    };

    // "requestBillingAgreement" we be true when "paupal_pay_later" functionality will be enabled or when Checkout with Vault is enabled
    // Vault mode should be also enabled
    if (requestBillingAgreement) {
        paymentObjectData.requestBillingAgreement = requestBillingAgreement;

        if (mainPayPalConfigurations.billingAgreementDetails) {
            paymentObjectData.billingAgreementDetails = {
                description: mainPayPalConfigurations.billingAgreementDetails.description
            };
        }
    }

    // Case when process from PDP & Cart & Mini Cart & Checkout and basket isn't empty or we on Account page
    if (shippingAddress) {
        paymentObjectData.shippingAddressEditable = false;
        paymentObjectData.shippingAddressOverride = {
            line1: shippingAddress.line1,
            line2: shippingAddress.line2,
            city: shippingAddress.city,
            state: shippingAddress.state,
            postalCode: shippingAddress.postalCode,
            countryCode: shippingAddress.countryCode,
            phone: shippingAddress.phone,
            recipientName: shippingAddress.recipientName
        };
    }

    return paymentObjectData;
};

/**
 * Callback triggers when buyer clicked on PayPal button
 * @param {Object} _ first not used param
 * @param {Object} actions BT actions object
 */
// eslint-disable-next-line no-unused-vars
PayPalBaseModel.prototype.onPayPalButtonClickCallback = function (fundingSourceResponse) {
    // Hide custom error on buyer PayPal button click.
    this.errorHandlingModel.hideError();
    this.basketData = this.basketDataUrl ? requestsHelper.getBasketData(this.basketDataUrl) : null;

    if (document.querySelector('#braintreePaypalFundingSource')) {
        document.querySelector('#braintreePaypalFundingSource').value = fundingSourceResponse.fundingSource;
    }
};

/**
 * Method triggers when error happens during PayPal modal payment processing
 * THIS METHOD CAN BE OVERRIDDEN in children class
 * @param {Object} error error object with "message" and "code" inside
 */
PayPalBaseModel.prototype.onErrorPaymentCallback = function (error) {
    this.errorHandlingModel.showErrorByObject(error);
};

/**
 * This method is executed when creating paypalCheckoutInstance. Should contain configs for "braintree.paypalCheckout.create" method.
 * Should only be used inside "braintreePayPalSDK" file
 * @param {Object} btClientInstance Braintree client instance
 * @returns {Object} object with additional configurations
 */
PayPalBaseModel.prototype.payPalCheckoutInstanceConfigs = function (btClientInstance) {
    return {
        client: btClientInstance
    };
};

/**
 * This method is executed when loading PayPal SDK
 * @returns {Object} object with Checkout mode PayPal SDK configs
 */
PayPalBaseModel.prototype.payPalCheckoutSdkConfigs = function () {
    var isChangePaymentButtonActive = this.payPalConfigurations.changePMButtonEnabled;
    var intent = this.payPalConfigurations.options.intent;
    var currency = this.payPalConfigurations.options.currency;
    var enableFundingList = this.payPalConfigurations.options.enableFundingList;
    var disableFundingList = this.payPalConfigurations.options.disableFundingList;
    var payPalSDKConfigObject = {
        intent: intent,
        currency: currency,
        components: 'buttons,messages',
        commit: false
    };
    // Functionality for disabling Debit/Credit Card Button when Change Payment Method Button is enabled
    if (isChangePaymentButtonActive && disableFundingList !== undefined) {
        disableFundingList += ',card';
    } else if (isChangePaymentButtonActive && disableFundingList === undefined) {
        disableFundingList = 'card';
    }

    if (enableFundingList !== undefined) {
        payPalSDKConfigObject['enable-funding'] = enableFundingList;
    }

    if (disableFundingList !== undefined) {
        payPalSDKConfigObject['disable-funding'] = disableFundingList;
    }

    return payPalSDKConfigObject;
};

/**
 * This method is executed when loading PayPal SDK
 * @returns {Object} object with Vault mode PayPal SDK configs
 */
PayPalBaseModel.prototype.payPalVaultSdkConfigs = function () {
    var isChangePaymentButtonActive = this.payPalConfigurations.changePMButtonEnabled;
    var enableFundingList = this.payPalConfigurations.options.enableFundingList;
    var disableFundingList = this.payPalConfigurations.options.disableFundingList;
    // Functionality for disabling Debit/Credit Card Button when Change Payment Method Button is enabled
    if (isChangePaymentButtonActive && disableFundingList !== undefined) {
        disableFundingList += ',card';
    } else if (isChangePaymentButtonActive && disableFundingList === undefined) {
        disableFundingList = 'card';
    }

    var payPalVaultSdkConfigsObject = {
        vault: true,
        components: 'buttons,messages'
    };

    if (enableFundingList !== undefined) {
        payPalVaultSdkConfigsObject['enable-funding'] = enableFundingList;
    }

    if (disableFundingList !== undefined) {
        payPalVaultSdkConfigsObject['disable-funding'] = disableFundingList;
    }

    return payPalVaultSdkConfigsObject;
};

/* -------------------- Payment logic -------------------- */

/**
 * Function which should be called when we need to init PayPal payment button
 * @returns {Promise} PayPal button
 */
PayPalBaseModel.prototype.initPayment = function () {
    var CurrentPayPalPaymenProcessorModelInstance = this;
    var result;

    result = this.braintreePayPalSDK(
        this.btClientInstancePromise,
        this.isVaultMode,
        this.payPalConfigurations,
        this.payPalButtonSelector,
        CurrentPayPalPaymenProcessorModelInstance
    );

    return result;
};

/**
 * Generate fraud data if enabled
 */
PayPalBaseModel.prototype.generateFraudData = function () {
    if (this.isFraudToolsEnabled) {
        this.fraudDataPromise = braintreeGeneral.collectFraudData(this.btClientInstancePromise);
    }
    // Don't need "else" case, since default value was inited in constructor
};

module.exports = PayPalBaseModel;
