'use strict';

var loaderInstance = require('../../loaderHelper');
var venmoButton = require('./venmoButton');
var venmoList = require('./venmoList');
var venmoHelper = require('../helpers/venmoHelper');
var venmoSessionAccount = require('./venmoSessionAccount');

var $braintreeVenmoNonce = document.querySelector('#braintreeVenmoNonce');
var $braintreeVenmoUserId = document.querySelector('#braintreeVenmoUserId');
var $submitShippingBtn = document.querySelector('.button, .submit-shipping');
var $venmoContent = document.querySelector('.js_braintree_venmoContent');
var $venmoBillingInput = document.querySelector('#braintreeVenmoBilling');
var $continueButton = document.querySelector('button.submit-payment');
var $venmoButton = document.querySelector('.js_braintree_venmo_button');
var $venmoAccountContainer = document.querySelector('.js_braintree_used_venmo_account');
var $sessionVenmoAccount = document.querySelector('#sessionVenmoAccount');
var $newVenmoAccount = document.querySelector('#newVenmoAccount');
var hashChange;

/**
 * Process Venmo on the Billing/Checkout Page
 * @param {Object} data tokenized Venmo payload data
 */
function processCheckoutFlow(data) {
    var $loaderContainer = venmoHelper.createLoaderContainter(document.querySelector('.page'));
    loaderInstance($loaderContainer).show();

    $braintreeVenmoNonce.value = data.nonce;
    $braintreeVenmoUserId.value = data.details.username;
    // In case when stage is correct we proceed with usual flow
    if (window.history.state !== 'shipping') {
        $continueButton.click();
        document.querySelector('.venmo-braintree-loader').remove();

        return;
    }
    // In case when Venmo has returned from Venmo app to NOT correct stage (for example, to shipping stage)
    // we redirect user to correct stage and set required data
    venmoHelper.updateBillingAddressFormValues(JSON.parse($venmoBillingInput.value));
    // Move to stage payment
    $submitShippingBtn.click();
    $venmoButton.setAttribute('data-is-valid-stage', true);
    document.querySelector('.venmo-braintree-loader').remove();
}

/**
 * Inits Venmo checkout components
 * @param {Object} btVenmoModel Braintree Venmo model
 */
function init(btVenmoModel) {
    // MutationObserver is triggered on each URL change to avoid conflict between URL passed to Venmo application
    // and URL with already changed stage on storefront (checkout stage)
    // case when user is redirected from Venmo application
    hashChange = new MutationObserver(function (mutations) {
        var mutation = mutations.find(function (element) {
            return element.attributeName === 'data-checkout-stage' && element.target.dataset.checkoutStage === 'payment';
        });

        if (!mutation) {
            return false;
        }

        if ($braintreeVenmoNonce.value && JSON.parse($venmoButton.getAttribute('data-is-valid-stage'))) {
            $venmoButton.setAttribute('data-is-valid-stage', false);
            $continueButton.click();

            return true;
        }
        // Case when user has proceeded with Venmo session account and clicked on "edit" button
        if ($venmoContent.classList.contains('active') && mutation.oldValue === 'placeOrder') {
            // Show dropdown with used Venmo session account
            venmoSessionAccount.showVenmoAccount();

            if ($braintreeVenmoUserId && $braintreeVenmoUserId.value !== 'Venmo') {
                $venmoAccountContainer.classList.remove('used-venmo-account-hide');
                $venmoAccountContainer.classList.add('used-venmo-account');
                $sessionVenmoAccount.classList.remove('used-venmo-account-hide');
                $sessionVenmoAccount.classList.add('used-venmo-account');
                $sessionVenmoAccount.textContent = $braintreeVenmoUserId.value;
                $sessionVenmoAccount.selected = true;
                $newVenmoAccount.selected = false;
            }

            // Set attribute to show "place order" button
            $venmoContent.setAttribute('data-paypal-is-hide-continue-button', false);
            // Hide Venmo button
            $venmoButton.setAttribute('data-is-hide-venmo-button', true);
        }
    });

    hashChange.observe(document.querySelector('#checkout-main'), { attributeOldValue: true });

    // Venmo button functionality is initiated once in case if it was not inited yet
    // based on existence of window.venmoCheckoutButtonInited (set while Venmo button initiation)
    if (!window.venmoCheckoutButtonInited) {
        venmoButton.init(btVenmoModel, $venmoButton, true);
        venmoList.init();
    }

    var sessionPaymentMethodId = JSON.parse($venmoContent.getAttribute('data-braintree-config')).sessionPaymentMethodId;
    // if exists Venmo session account - we make Venmo tab active on page reload
    if (sessionPaymentMethodId === 'Venmo') {
        document.querySelector('.venmo-tab').click();
    }
}

module.exports = {
    init,
    processCheckoutFlow
};
