/* eslint-disable no-console */
/* eslint-disable block-scoped-var */
'use strict';

// Braintree SDK model builder, we need to init Braintree Credit Card model on this level, since we are using it on step of "creditCardFields.init()"
// and on step of "creditCardPayment.init()".
var braintreeCreditCardModelBuilder = require('./braintreesdk/braintreeCreditCardModelBuilder');

// Components
var creditCardList = require('./components/creditCardList');
var creditCardFields = require('./components/creditCardFields');
var creditCardPaymentProcessing = require('./components/creditCardPaymentProcessing');
var creditCardAccount = require('./components/creditCardAccount');

// Modelds
var ErrorHandlingBaseModel = require('../creditcard/errorhandler/errorHandlingBaseModel');
var ErrorHandlingCheckoutModel = require('../creditcard/errorhandler/errorHandlingCheckoutModel');

var $continueButton = document.querySelector('button.submit-payment');

var btCreditCardModelResponse;
var creditCardMessages;

/**
 * Inits Credit Card functionality on the Account Page
 * @returns {void}
 */
function initAccount() {
    var errorHandlingBaseModel = new ErrorHandlingBaseModel(creditCardMessages);

    if (!btCreditCardModelResponse || btCreditCardModelResponse.error) {
        errorHandlingBaseModel.creditCardErrorHandler(btCreditCardModelResponse.message);
    }

    creditCardAccount.init(
        btCreditCardModelResponse.btCreditCardModel,
        errorHandlingBaseModel,
        creditCardMessages
    );

    creditCardFields.init(
        btCreditCardModelResponse.btCreditCardModel,
        $continueButton,
        errorHandlingBaseModel
    );
}

/**
 * Inits Credit Card functionality on the Checkout Page
 * @returns {void}
 */
function initCheckout() {
    var errorHandlingCheckoutModel = new ErrorHandlingCheckoutModel(creditCardMessages);

    if (!btCreditCardModelResponse || btCreditCardModelResponse.error) {
        errorHandlingCheckoutModel.creditCardErrorHandler(btCreditCardModelResponse.message);
    }

    // Call server to update data from basket
    btCreditCardModelResponse.btCreditCardModel.updateOrderData();

    var boltEnable = $('.boltEnable').val();
    if (boltEnable !== "true") {

        creditCardFields.init(
            btCreditCardModelResponse.btCreditCardModel,
            $continueButton,
            errorHandlingCheckoutModel
        );

        creditCardList.init(
            btCreditCardModelResponse.btCreditCardModel,
            errorHandlingCheckoutModel
        );

        creditCardPaymentProcessing.init(errorHandlingCheckoutModel);
    }
}

/**
 * Inits 'braintreeCreditCardModelBuilder' on creditCard level
 */
function init() {
    btCreditCardModelResponse = braintreeCreditCardModelBuilder.init();

    if (!btCreditCardModelResponse.error) {
        creditCardMessages = btCreditCardModelResponse.btCreditCardConfigs.messages;
    }
}

module.exports = {
    initAccount,
    initCheckout,
    init
};
