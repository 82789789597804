'use strict';

/**
 * Error Handling constructor
 * @param {Object} errorMessages Braintree credit card error messages
 */
function ErrorHandlingBaseModel(errorMessages) {
    this.$errorContainer = $('.cart-error-messaging');
    this.errorMessages = errorMessages;
}

// General method which should be used for cases when error came from Braintree
ErrorHandlingBaseModel.prototype.showErrorByObject = function (error) {
    var msg = error.message;
    var code = error.code;
    var errorMessage = this.errorMessages[code] || msg || this.errorMessages.CLIENT_GATEWAY_NETWORK;

    this.createErrorNotification(errorMessage);
};

/**
 * Shows error by message
 * @param {string} message Error message
 */
ErrorHandlingBaseModel.prototype.showErrorByMessage = function (message) {
    this.createErrorNotification(message);
};

/**
 * ---- Error container which will be used on Cart page ----
 * ---- For all other cases this method will be overwritten by corresponding one ----
 *
 * Method responsible for Error Notification generation
 * @param {string} message Message to display inside error notification
 */
ErrorHandlingBaseModel.prototype.createErrorNotification = function (message) {
    this.$errorContainer.append(
        `<div class="alert alert-danger alert-dismissible valid-cart-error fade show cartError custom-cart-error" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            ${message}
        </div>`
    );

    this.$errorContainer.show();
    window.scrollTo(0, 0);
};

/**
 * ---- Method which should hide error container and make it empty. On Cart we use this one ----
 * ---- For all other cases this method will be overwritten by corresponding one ----
 *
 * Hide error notification
 */
ErrorHandlingBaseModel.prototype.hideError = function () {
    this.$errorContainer.hide();
    this.$errorContainer.empty();
};

/**
 * Shows order total equal 0 error
 */
ErrorHandlingBaseModel.prototype.showOrderTotalError = function () {
    var googlePayErrorMessages = require('../constants/errorMessages');
    var zeroAmountErrorMsg = googlePayErrorMessages.ORDER_TOTAL_0;

    this.showErrorByMessage(zeroAmountErrorMsg);

    throw zeroAmountErrorMsg;
};

/**
 * Handles Google Pay error by types
 * @param {Object} error Error object or error message
 */
ErrorHandlingBaseModel.prototype.googlePayErrorHandler = function (error) {
    var googlePayErrorMessages = require('../constants/errorMessages');

    if (error.name === 'BraintreeError') {
        this.showErrorByObject(error);
    } else if (error.statusCode === 'DEVELOPER_ERROR') {
        this.showErrorByMessage(error.statusMessage);
    } else if (error.message !== googlePayErrorMessages.GOOGLE_PAY_WINDOW_CLOSED) {
        this.showErrorByMessage(error.message);
    }
};

module.exports = ErrorHandlingBaseModel;
