'use strict';

var helper = require('../../helper');
var loaderInstance = require('../../loaderHelper');
var venmoButton = require('../components/venmoButton');
var venmoHelper = require('../helpers/venmoHelper');
var ErrorHandlingBaseModel = require('../errorhandler/errorHandlingBaseModel');

var $venmoButton = document.querySelector('.js_braintree_accountVenmoButton');
var venmoLoader;

/**
 * Makes Venmo account as default one
 * @param {Event} e event to identify target element
 */
function makeDefaultVenmo(e) {
    var target = e.target;
    var url = target.getAttribute('data-make-default-url');
    var id = target.getAttribute('data-id');
    var paymentMethodID = document.querySelector('.venmo-accounts').getAttribute('data-payment-method-id');
    var $loaderContainter = document.querySelector('#' + target.getAttribute('data-loader'));
    var loader = loaderInstance($loaderContainter);
    // Hide venmo account error container
    var braintreeVenmoConfig = JSON.parse($venmoButton.getAttribute('data-braintree-config'));
    var errorHandlingBaseModel = new ErrorHandlingBaseModel(braintreeVenmoConfig.messages);
    errorHandlingBaseModel.hideError();

    loader.show();

    helper.setDefaultProperty({
        url: url,
        id: id,
        paymentMethodID: paymentMethodID,
        loader: loader
    });
}

/**
 * Remove Venmo account
 * @param {Event} e event to identify target element
 */
function removeVenmoPayment(e) {
    var target = e.target;

    if (target && JSON.parse(target.getAttribute('data-is-remove-allowed'))) {
        var $loaderContainter = document.querySelector('#' + target.getAttribute('data-loader'));
        venmoLoader = loaderInstance($loaderContainter);
        venmoLoader.show();

        $.get(target.getAttribute('data-url') + '?UUID=' + target.getAttribute('data-id'))
            .then((data => {
                $('#uuid-' + data.UUID).remove();
                if (data.newDefaultAccount) {
                    document.querySelector('#uuid-' + data.newDefaultAccount + ' span').style.fontWeight = 'bold';
                    document.querySelector('#uuid-' + data.newDefaultAccount + ' button.braintree-make-default-card').style.display = 'none';
                }
                venmoLoader.hide();
            }))
            .fail(() => {
                location.reload();
                venmoLoader.hide();
            });
    } else {
        var removeIsNotAllowedMsg = target.getAttribute('data-message-remove-is-not-allowed');
        var braintreeVenmoConfig = $venmoButton ? JSON.parse($venmoButton.getAttribute('data-braintree-config')) : removeIsNotAllowedMsg;
        var errorHandlingBaseModel = new ErrorHandlingBaseModel(braintreeVenmoConfig.messages);
        errorHandlingBaseModel.showErrorByMessage(removeIsNotAllowedMsg);
    }
}

/**
 * Creates Venmo form data on account page
 * @param {string} nonce Braintree Venmo nonce
 * @param {string} userId Braintree Venmo userID
 * @returns {Object} object with Venmo form data
 */
function createVenmoAccountFormData(nonce, userId) {
    var venmoAccountFormFields = $venmoButton.getAttribute('data-venmo-account-form-fields');
    var csrfToken = document.querySelector('.js_braintree_accountVenmoButton_wrapper #csrf_token');

    var venmoAccountFormData = helper.createPaymentFormData(venmoAccountFormFields, {
        nonce: nonce,
        userId: userId
    });

    venmoAccountFormData.append(csrfToken.name, csrfToken.value);

    return venmoAccountFormData;
}

/**
 * Stores new Venmo account
 * @param {Object} data tokenized payload data with Venmo nonce
 * @returns {ajax} call to Braintree-AccountAddVenmoHandle endpoint
 */
function storeNewVenmoAccount(data) {
    var venmoAddAccountHandlerUrl = $venmoButton.getAttribute('data-venmo-add-account-handler');
    var venmoAccountFormData = createVenmoAccountFormData(data.nonce, data.details.username);

    venmoLoader.show();

    return $.ajax({
        type: 'POST',
        url: venmoAddAccountHandlerUrl,
        data: venmoAccountFormData,
        contentType: false,
        processData: false
    })
        .then((paymentData) => {
            venmoHelper.hideVenmoAccountBtn();
            venmoLoader.hide();

            $.get(paymentData.renderAccountsUrl)
                .then((tplData => {
                    document.querySelector('.venmo-accounts').innerHTML = tplData;
                    document.querySelectorAll('.venmo-accounts .remove-bt-payment').forEach(function (el) {
                        el.addEventListener('click', removeVenmoPayment);
                    });
                }));
        })
        .fail(({ responseJSON }) => {
            venmoLoader.hide();

            var braintreeVenmoConfig = JSON.parse($venmoButton.getAttribute('data-braintree-config'));
            var errorHandlingBaseModel = new ErrorHandlingBaseModel(braintreeVenmoConfig.messages);
            errorHandlingBaseModel.showErrorByMessage(responseJSON.error);
        });
}

/**
 * Inits Venmo functionality
 * @param {Object} btVenmoModel Braintree Venmo model
 */
function init(btVenmoModel) {
    var isVenmoButtonEnabledOnAccountPage = Boolean(document.querySelector('.js_braintree_accountVenmoButton'));
    var isVenmoAccountBlockExists = Boolean(document.querySelector('.venmo-accounts'));
    var $braintreeVenmoLoader = document.querySelector('#braintreeVenmoAccLoader');

    venmoLoader = loaderInstance($braintreeVenmoLoader);

    if (isVenmoButtonEnabledOnAccountPage || isVenmoAccountBlockExists) {
        // "Make default" button functionality initiation
        $('.venmo-accounts').on('click', '.braintree-make-default-card', makeDefaultVenmo);
        // "Remove" button functionality initiation
        $('.venmo-accounts').on('click', '.remove-bt-payment', removeVenmoPayment);
    }

    if (isVenmoButtonEnabledOnAccountPage) {
        $('.add-venmo-account').click(function () {
            // Hide venmo account error container
            var braintreeVenmoConfig = JSON.parse($venmoButton.getAttribute('data-braintree-config'));
            var errorHandlingBaseModel = new ErrorHandlingBaseModel(braintreeVenmoConfig.messages);
            errorHandlingBaseModel.hideError();

            venmoHelper.showVenmoAccountBtn();

            if (!window.venmoAccountButtonInited) {
                window.venmoAccountButtonInited = true;

                venmoButton.init(btVenmoModel, $venmoButton);
            }
        });
    }
}

module.exports = {
    init,
    storeNewVenmoAccount
};
