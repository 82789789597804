'use strict';

var creditCardFormFieldHelper = require('../helpers/creditCardFormFieldsHelper');
var creditCardVaultManagerComponent = require('./creditCardVaultManager');
var creditCardPaymentProcessingHelper = require('../helpers/creditCardPaymentProcessingHelper');
var creditCardHelper = require('../helpers/creditCardHelper');

/**
 * Makes the necessary setting for 'Save Credit Card block' on the Billing Page
 * @param {boolean} saveCreditCard true/false
 * @param {boolean} displaySaveCreditCardBlock true/false
 */
function saveCreditCardBlockManipulation(saveCreditCard, displaySaveCreditCardBlock) {
    var $saveCreditCard = document.querySelector('#braintreeSaveCreditCard');
    var $braintreeSaveCardContainer = document.querySelector('#braintreeSaveCardContainer');

    if ($braintreeSaveCardContainer) {
        $braintreeSaveCardContainer.style.display = displaySaveCreditCardBlock ? 'block' : 'none';
        $saveCreditCard.checked = saveCreditCard;
    }
}

/**
 * Handles changing of Credit Card list on the Billing Page
 * @param {Constructor} errorHandlingModel Error handling model
 */
function cardListChange(errorHandlingModel) {
    var btUtils = require('../../braintreeUtils');

    var $creditCardList = creditCardFormFieldHelper.getCreditCardListContainer();
    var $braintreeCreditCardNonce = creditCardFormFieldHelper.getCreditCardNonceFieldContainer();
    var $braintreeCardPaymentMethod = document.querySelector('#braintreeCardPaymentMethod');

    var creditCardOption = $creditCardList.selectedOptions[0];
    var creditCardOptionID = creditCardOption.id;
    var creditCardFieldsToDisplayArray = creditCardFormFieldHelper.getCCFieldsToDisplay().asArray;
    var creditCardHostedFieldsArray = creditCardFormFieldHelper.getCCFields().asArray;
    var creditCardFiledsToDisplayObject = creditCardFormFieldHelper.getCCFieldsToDisplay().asObject;

    // Variables only for "braintreeSessionCreditAccount" case
    var selectedCreditCard;
    var nonce = '';
    var saveCreditCard = true;
    var showSaveCreditCardBlock = true;

    // Clear error messages on the Billing Page
    errorHandlingModel.hideError();

    switch (creditCardOptionID) {
        // When buyer selected "new cc"
        case 'newCardAccount':
            // Hide 'toDisplay' fields
            creditCardFormFieldHelper.hideCardElements(creditCardFieldsToDisplayArray);

            creditCardFormFieldHelper.showCardElements(creditCardHostedFieldsArray);

            // Display "Save Credit Card" block, and mark it as checked
            saveCreditCardBlockManipulation(saveCreditCard, showSaveCreditCardBlock);

            $braintreeCreditCardNonce.value = '';
            $braintreeCardPaymentMethod.value = 'CREDIT';
            break;

        case 'braintreeSessionCreditAccount':
            selectedCreditCard = btUtils.getSelectedData($creditCardList);
            nonce = selectedCreditCard['data-nonce'].value;
            saveCreditCard = selectedCreditCard['data-save-card'].value;

            // Display "Save Credit Card" block, and mark it as checked
            saveCreditCardBlockManipulation(saveCreditCard, showSaveCreditCardBlock);

            // Add values to the "toDisplay" and hidden input fields.
            creditCardFormFieldHelper.setCardFields(selectedCreditCard, creditCardFiledsToDisplayObject);

            creditCardFormFieldHelper.hideCardElements(creditCardHostedFieldsArray);
            creditCardFormFieldHelper.showCardElements(creditCardFieldsToDisplayArray);

            $braintreeCreditCardNonce.value = nonce;
            $braintreeCardPaymentMethod.value = selectedCreditCard['data-payment-method'].value.toUpperCase();
            break;

        // Case for saved credit cards
        default:
            saveCreditCard = false;
            showSaveCreditCardBlock = false;
            selectedCreditCard = btUtils.getSelectedData($creditCardList);

            // Add values to the 'toDisplay' and hidden input fields
            creditCardFormFieldHelper.setCardFields(selectedCreditCard, creditCardFiledsToDisplayObject);

            // Show these fields to the buyer
            creditCardFormFieldHelper.showCardElements(creditCardFieldsToDisplayArray);

            // Hide hosted fields (since we don't need them)
            creditCardFormFieldHelper.hideCardElements(creditCardHostedFieldsArray);

            // Display "Save Credit Card" block, and uncheck it
            saveCreditCardBlockManipulation(saveCreditCard, showSaveCreditCardBlock);

            $braintreeCreditCardNonce.value = nonce;
            $braintreeCardPaymentMethod.value = selectedCreditCard['data-payment-method'].value.toUpperCase();
            break;
    }
}

/**
 * Hide stored card if the new card is the same as already stored
 */
function hideStoredCardAsSessionCard() {
    var $sessionCreditCard = $('#braintreeSessionCreditAccount');
    var isSessionCardVisible = $sessionCreditCard.css('display') !== 'none';

    if (isSessionCardVisible) {
        var sessionCardType = $sessionCreditCard.data('type').toLowerCase();
        var sessionCardLastFour = $sessionCreditCard.data('last-four').toString();
        var storedAsSessionCardExists;

        storedAsSessionCardExists = creditCardPaymentProcessingHelper.hideAlreadyStoredCard(sessionCardLastFour, sessionCardType);

        if (storedAsSessionCardExists) {
            // Hide CC save block and unclick "save" checkbox
            creditCardHelper.hideSaveCreditCardBlock(true);
            creditCardHelper.saveCreditCard(false);
        }
    }
}

/**
 * Inits Credit Card list functionality
 * @param {Object} btCreditCardModel BT Credit Card model
 * @param {Constructor} errorHandlingModel errorHandlingModel Error handling model
 */
function init(btCreditCardModel, errorHandlingModel) {
    var $creditCardList = document.querySelector('#braintreeCreditCardList');
    var creditCardListExist = Boolean($creditCardList);
    var clientInstancePromise = btCreditCardModel.getClientInstancePromise();

    if (creditCardListExist) {
        $creditCardList.addEventListener('change', function () {
            cardListChange(errorHandlingModel);
        });
    }

    cardListChange(errorHandlingModel);
    // Only for logged in buyer with stored cards in wallet
    creditCardVaultManagerComponent.removeFromDomCardsWhichAreNotInVaultMgr(clientInstancePromise);
    hideStoredCardAsSessionCard();
}


module.exports = {
    init,
    cardListChange
};
