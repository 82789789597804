'use strict';

var helper = require('../../helper');
var venmoAccount = require('./venmoAccount');
var venmoCheckout = require('./venmoCheckout');
var venmoConstants = require('../constants/venmoConstants');
var venmoProcessingHelper = require('../helpers/venmoProcessingHelper');
var ErrorHandlingBaseModel = require('../errorhandler/errorHandlingBaseModel');

/**
 * Process Venmo flow for Account/Billing Page flows
 * @param {Object} venmoData Venmo data received from tokenize
 */
function processVenmo(venmoData) {
    var $venmoButton = document.querySelector('.js_braintree_accountVenmoButton') || document.querySelector('.js_braintree_venmo_button');

    if ($venmoButton && JSON.parse($venmoButton.getAttribute('data-braintree-config'))) {
        var braintreeVenmoConfig = JSON.parse($venmoButton.getAttribute('data-braintree-config'));
        var errorHandlingBaseModel = new ErrorHandlingBaseModel(braintreeVenmoConfig.messages);
        errorHandlingBaseModel.hideError();
    }

    var venmoFlowName = venmoProcessingHelper.getVenmoFlowName();

    switch (venmoFlowName) {
        case venmoConstants.FLOW_NAME_ACCOUNT:

            venmoAccount.storeNewVenmoAccount(venmoData);
            break;

        case venmoConstants.FLOW_NAME_BILLING_CHECKOUT:
            helper.removeActiveSessionPayment();
            venmoCheckout.processCheckoutFlow(venmoData);
            break;

        default:
            break;
    }
}

module.exports = {
    processVenmo
};
