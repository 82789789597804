'use strict';

var PayPalCartModel = require('../models/payPalCartModel');
var ErrorHandlingCartModel = require('../errorhandler/errorHandlingCartModel');

/**
 * Initialize Cart page PayPal button
 * @param {Promise} clientInstancePromise Client instance promise
 */
function init(clientInstancePromise) {
    var payPalButtonSelector = '.cart-page .js_braintree_paypal_cart_button';
    var payPalEnabledOnCart = Boolean(document.querySelector(payPalButtonSelector));
    if (payPalEnabledOnCart) {
        var $payPalButton = document.querySelector(payPalButtonSelector);
        var payPalConfigurations = JSON.parse($payPalButton.getAttribute('data-braintree-config'));

        var isVaultMode = payPalConfigurations.options.flow !== 'checkout';
        var requestBillingAgreement = Boolean(payPalConfigurations.options.requestBillingAgreement);
        var isFraudToolsEnabled = payPalConfigurations.isFraudToolsEnabled;
        var $csrfToken = document.querySelector('.braintree-cart-paypal-buttons-wrap #csrf_token');
        var $loaderContainer = document.querySelector('.braintreePayPalLoader');

        var ErrorHandling = new ErrorHandlingCartModel(payPalConfigurations.messages);
        var PayPalExpress = new PayPalCartModel(
            ErrorHandling,
            clientInstancePromise,
            payPalButtonSelector,
            payPalConfigurations,
            isVaultMode,
            requestBillingAgreement,
            isFraudToolsEnabled,
            $csrfToken,
            $loaderContainer
        );

        // Generate Fraud Data if enabled
        PayPalExpress.generateFraudData();
        // Init PayPal button
        PayPalExpress.initPayment();
    }
}

module.exports = {
    init
};
