'use strict';

/**
 * Error Handling constructor
 * @param {Object} errorMessages object with error messages
 */
function ErrorHandlingBaseModel(errorMessages) {
    this.errorMessages = errorMessages;
}

/**
 * ---- General method which should be used for cases when error came from Braintree ----
 * Show error notification by error Object from BT
 * @param {Object} error error object from Bt
 */
ErrorHandlingBaseModel.prototype.showErrorByObject = function (error) {
    var msg = error.message;
    var code = error.code;
    var errorMessage = this.errorMessages[code] || msg || this.errorMessages.CLIENT_GATEWAY_NETWORK;

    this.createErrorNotification(errorMessage);
};

/**
 * ---- General method which should be used for cases when error came from Braintree ----
 * Show error notification by message
 * @param {string} message message to display inside error notification
 */
ErrorHandlingBaseModel.prototype.showErrorByMessage = function (message) {
    this.hideError();
    this.createErrorNotification(message);
};

/**
 * ---- Error container which will be used on Account and PDP ----
 * ---- For all other cases this method will be overwritten by corresponding one ----
 *
 * Method responsible for Error Notification generation
 * @param {string} message message to display inside error notification
 */
ErrorHandlingBaseModel.prototype.createErrorNotification = function (message) {
    var $errorContainer = $('.error-messaging');

    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
    'fade show" role="alert">' +
    '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
    '<span aria-hidden="true">&times;</span>' +
    '</button>' + message + '</div>';

    $errorContainer.append(errorHtml);
    $errorContainer.show();
    window.scrollTo(0, 0);
};

/**
 * ---- Method which should hide error container and make it empty. On Account and PDP we use this one ----
 * ---- For all other cases this method will be overwritten by corresponding one ----
 *
 * Hide error notification
 */
ErrorHandlingBaseModel.prototype.hideError = function () {
    var $errorContainer = $('.error-messaging');
    var $venmoErrorContainer = $('#venmoAccountErrorContainer');

    if ($venmoErrorContainer) {
        $venmoErrorContainer.hide();
        $venmoErrorContainer.empty();
    }

    $errorContainer.hide();
    $errorContainer.empty();
};

/**
 * Method which should shows error on the Account Page and hides Venmo block in case when browser is not supported
 * @param {Object} braintreeVenmoConfig Braintree Venmo config
 */
ErrorHandlingBaseModel.prototype.handleNoBrowserSupportErrorOnAccount = function (braintreeVenmoConfig) {
    this.showErrorByMessage(braintreeVenmoConfig.messages.VENMO_BROWSER_NOT_SUPPORTED);
};

/**
 *  Method which should hide Venmo tab on the Billing Page - in case when browser is not supported
 */
ErrorHandlingBaseModel.prototype.hideVenmoTab = function () {
    document.querySelector('.js_braintree_venmo_button').parentElement.firstElementChild.style.display = 'none';
    document.querySelector('.payment-options[role=tablist] .nav-item[data-method-id="Venmo"]').style.display = 'none'; // Remove the venmo select payment method radiobutton
};

/**
 * Method which handle error flows in case when browser is not supported for Billing/Account Pages
 * @param {Object} braintreeVenmoConfig Braintree Venmo config
 */
ErrorHandlingBaseModel.prototype.handleNoBrowserSupport = function (braintreeVenmoConfig) {
    var $venmoAccountWrapper = document.querySelector('.js_braintree_accountVenmoButton_wrapper');

    if ($venmoAccountWrapper) {
        this.handleNoBrowserSupportErrorOnAccount(braintreeVenmoConfig);
    } else {
        this.hideVenmoTab();
    }
};

/**
 * Method which show checkout error message on top of the page
 * @param {string} message Error message
 */
ErrorHandlingBaseModel.prototype.showCheckoutErrorMessage = function (message) {
    document.querySelector('.error-message-text').textContent = '';
    document.querySelector('.error-message').style.display = 'block';
    document.querySelector('.error-message-text').append(message);

    window.scrollTo(0, 0);
};

module.exports = ErrorHandlingBaseModel;
