'use strict';

var PayPalBaseModel = require('./payPalBaseModel');
var payPalExpressModelHelper = require('../helpers/payPalExpressModelHelper');
var billingFormHelper = require('../helpers/payPalBillingFormHelperGlobal');
var requestHelper = require('../helpers/payPalRequestsHelperGlobal');

/**
 * PayPal express model constructor
 * @param {Object} errorHandlingModel error handling model
 * @param {Promise} btClientInstancePromise BT client instance promise
 * @param {string} payPalButtonSelector PayPal button selector
 * @param {Object} payPalConfigurations configs from server (taken from data attr)
 * @param {boolean} isVaultMode "true" in case if vault mode is enabled
 * @param {boolean} requestBillingAgreement "true" in case if billing agreement should be requested
 * @param {boolean} isFraudToolsEnabled "true" in case if fraud tools is enabled
 * @param {Object} $csrfToken CSRF token js container
 * @param {Object} $loaderContainer loader js container
 */
function PayPalExpressModel(
    errorHandlingModel,
    btClientInstancePromise,
    payPalButtonSelector,
    payPalConfigurations,
    isVaultMode,
    requestBillingAgreement,
    isFraudToolsEnabled,
    $csrfToken,
    $loaderContainer
) {
    // Init PayPalBaseModel constructor
    PayPalBaseModel.call(
        this,
        errorHandlingModel,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        isVaultMode,
        requestBillingAgreement,
        isFraudToolsEnabled,
        $loaderContainer
    );

    this.$csrfToken = $csrfToken;
    this.changePMButtonEnabled = payPalConfigurations.changePMButtonEnabled;
}

// ES5 inheritance
PayPalExpressModel.prototype = Object.create(PayPalBaseModel.prototype);

// For more details see same method from "PayPalBaseModel"
// Add additional configs which are needed for "Change payment method button" feature
PayPalExpressModel.prototype.payPalCheckoutInstanceConfigs = function (btClientInstance) {
    var checkoutInstanceConfigs = PayPalBaseModel.prototype.payPalCheckoutInstanceConfigs.call(this, btClientInstance);

    if (this.changePMButtonEnabled) {
        checkoutInstanceConfigs.autoSetDataUserIdToken = true;
    }

    return checkoutInstanceConfigs;
};

/**
 * Method triggers when buyer clicked on PayPal button and PayPal modal is opening
 * @returns {Object} Amount and Shipping address from basket
 */
PayPalExpressModel.prototype.onOrderCreateCallback = function () {
    var orderData;
    var basketDataUrl = this.basketDataUrl;
    var isUseSavedPaypalAddress;

    this.basketData = requestHelper.getBasketData(basketDataUrl);

    isUseSavedPaypalAddress = this.changePMButtonEnabled && !this.basketData.shippingAddress;

    if (isUseSavedPaypalAddress) {
        this.savedPaypalAddress = payPalExpressModelHelper.getSavedPaypalAddress(this.payPalButtonSelector);
    }

    orderData = PayPalBaseModel.prototype.onOrderCreateCallback.call(this);

    return orderData;
};


// For more details see same method from "PayPalBaseModel"
// Create and submit billing data to the server. Redirect on order reivew checkout step
PayPalExpressModel.prototype.onApprovePaymentCallback = function (payload) {
    // Calling parrent method to proceed with PayPal payload validation
    var baseResponse = PayPalBaseModel.prototype.onApprovePaymentCallback.call(this, payload);

    // Error will be handled on BaseModel level
    if (baseResponse && baseResponse.error) {
        return;
    }

    this.loader.show();

    var PayPalExpressInstance = this;
    var payloadDetails = payload.details;
    var $payPalButtonWrapper = document.querySelector('.braintree-cart-paypal-buttons-wrap');
    var $braintreePayPalFundingSource = document.querySelector('#braintreePaypalFundingSource');
    var braintreePayPalFundingSource = $braintreePayPalFundingSource.value;

    var paypalCheckoutFormFields = JSON.parse($payPalButtonWrapper.getAttribute('data-paypal-checkout-form-fields'));
    var billingAddressData = billingFormHelper.createBillingAddressData(payloadDetails);
    var billingFormData;

    // Create form data only with billing address
    billingFormData = billingFormHelper.createBillingAddressFormData(paypalCheckoutFormFields, billingAddressData);

    billingFormHelper.appendCsrfTokenToFormData(billingFormData, PayPalExpressInstance.$csrfToken);
    billingFormHelper.appendBraintreePaypalFundingSourceToFormData(billingFormData, braintreePayPalFundingSource);

    // It will generate FFraud data only if it Fraud Data collection was enabled
    PayPalExpressInstance.fraudDataPromise
        .then(function (data) {
            if (!data.fraudDataCollectionDisabled) {
                billingFormHelper.appendFraudDataToFormData(billingFormData, data.deviceData);
            }
        })
        .then(function () {
            var shippingAddressData;

            // If shipping address was presented in PayPal payload, create a shipping address object,
            // stringify it and store in FormData as an input
            if (payloadDetails.shippingAddress) {
                shippingAddressData = payPalExpressModelHelper.createShippingAddressData(payloadDetails);

                payPalExpressModelHelper.appendShippingDataAsStringToFormData(billingFormData, shippingAddressData);
            }

            // Create a shipping address object, stringify it and store in FormData as an input
            billingFormHelper.appendBillingAddressAsStringToFormData(billingFormData, billingAddressData);

            billingFormHelper.appendEmailAsStringToFormData(billingFormData, payloadDetails.email);

            billingFormHelper.appendBtPaymentFieldsToFormData(billingFormData, payload);
        })
        .then(function () {
            // Submit customer form with email (CheckoutServices-SubmitCustomer)
            // as we skip step "login via guest/registered user" while express checkout
            // email is set only in case of guest checkout and if email is not already set
            payPalExpressModelHelper.submitCustomerForm(payloadDetails.email);
        })
        .then(function () {
            requestHelper.submitPaymentDataToServerPromise(
                PayPalExpressInstance.submitPaymentUrl,
                billingFormData,
                PayPalExpressInstance.placeOrderStageUrl,
                PayPalExpressInstance.loader,
                PayPalExpressInstance.errorHandlingModel
            );

            PayPalExpressInstance.loader.hide();
        });
};

module.exports = PayPalExpressModel;
