'use strict';

var helper = require('../../helper');
var paymentMethodGeneral = require('../../paymentMethodGeneral');

var $applePayButton = document.querySelector('.js_braintree_applepay_button');

var paymentData;
var $braintreeCartButtons;
var applePayCheckoutFormData;
var errHandlingModel;

/**
 * Expends Apple Pay Checkout Form with required data
 */
function expandApplePayCheckoutFormData() {
    var csrfToken = document.querySelector('.braintree-cart-buttons-wrap  #csrf_token');

    applePayCheckoutFormData.append(csrfToken.name, csrfToken.value);
    applePayCheckoutFormData.append('braintreeApplePayNonce', paymentData.nonce);
    applePayCheckoutFormData.append('braintreeApplePayDeviceDataInput', paymentData.deviceData);
    applePayCheckoutFormData.append('braintreeApplePayShippingAddress',
        JSON.stringify(paymentData.shippingAddress) || '{}'
    );
}

/**
 * Process Apple Pay on the Cart Page
 */
function processCartFlow() {
    var billingAddressData = paymentData.billingAddress;
    var paymentMethodName = JSON.parse($applePayButton.getAttribute('data-braintree-config')).paymentMethodName;
    var placeOrderUrl = $braintreeCartButtons.getAttribute('data-checkout-placeorder-url');
    var checkoutFromCartUrl = $braintreeCartButtons.getAttribute('data-checkout-from-cart-url');
    var checkoutFormFields = $braintreeCartButtons.getAttribute('data-checkout-form-fields');
    var paymentFieldData = helper.getPaymentFieldsData(billingAddressData, paymentMethodName);

    applePayCheckoutFormData = helper.createPaymentFormData(checkoutFormFields, paymentFieldData);
    expandApplePayCheckoutFormData();
    // submit customer form with email (CheckoutServices-SubmitCustomer)
    // as we skip step "login via guest/registered user" while express checkout
    // email is set only in case of guest checkout and if email is not already set
    paymentMethodGeneral.submitCustomerForm(billingAddressData.email);

    $.ajax({
        type: 'POST',
        url: checkoutFromCartUrl,
        data: applePayCheckoutFormData,
        contentType: false,
        processData: false,
        success: function (data) {
            if (data.error) {
                var errorMessage = '';

                if (data.fieldErrors.length) {
                    data.fieldErrors.forEach(function (error, index) {
                        var keys = Object.keys(error);

                        if (keys.length) {
                            errorMessage += `${keys[index].replace('dwfrm_billing_', '').replace('_', ' ')} ${data.fieldErrors[index][keys[index]]}. `;
                        }
                    });
                    errHandlingModel.applePayErrorHandler(errorMessage);
                }

                if (data.serverErrors.length) {
                    data.serverErrors.forEach(function (error) {
                        errorMessage += `${error}. `;
                    });
                    errHandlingModel.applePayErrorHandler(errorMessage);
                }

                if (data.cartError) {
                    window.location.href = data.redirectUrl;
                }

                return;
            }
            if (data.addressError) {
                window.location.href = data.redirectUrl;
                return;
            }

            window.location.href = placeOrderUrl;
        },
        error: function (err) {
            if (err && err.redirectUrl) {
                window.location.href = err.redirectUrl;
            }
        }
    });
}

/**
 * Inits Apple Pay Cart components
 * @param {Object} data Apple Pay payment data
 * @param {Constructor} errorHandlingModel Error handling model
 */
function init(data, errorHandlingModel) {
    paymentData = data;
    $braintreeCartButtons = document.querySelector('.braintree-cart-buttons-wrap');
    $applePayButton.setAttribute('data-is-inited', true);
    errHandlingModel = errorHandlingModel;

    processCartFlow();
}

module.exports = {
    init
};
