
'use strict';

/**
 * Add product to the Cart
 * @returns {Object} Response data from DW
 */
function addProductToCart() {
    var $bundleItem = $('.bundle-item');

    function getOptions($productContainer) {
        var options = $productContainer
            .find('.product-option')
            .map(function () {
                var $elOption = $(this).find('.options-select');
                var urlValue = $elOption.val();
                var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                    .data('value-id');
                return {
                    optionId: $(this).data('option-id'),
                    selectedValueId: selectedValueId
                };
            }).toArray();

        return JSON.stringify(options);
    }

    var pid = $('.product-detail:not(.bundle-item)').data('pid');
    var $btn = $('.braintree_pdp_button');
    var $productContainer = $btn.closest('.product-detail');

    var form = {
        pid: pid,
        quantity: $('.quantity-select').val()
    };

    if (!$bundleItem.length) {
        form.options = getOptions($productContainer);
    } else {
        var items = $bundleItem.map(function () {
            return {
                pid: $(this).find('.product-id').text(),
                quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
            };
        });
        form.childProducts = JSON.stringify(items.toArray());
    }

    var response = $.ajax({
        url: $('.add-to-cart-url').val(),
        method: 'POST',
        async: false,
        data: form
    }).responseJSON;
    response.pid = pid;
    return response;
}

/**
 * Get basket data
 * @param {string} url Braintree-GetOrderInfo URL
 * @returns {Promise} Promise with response from DW
 */
function getBasketDataPromise(url) {
    return $.ajax({
        url: url,
        method: 'GET'
    });
}

/**
 * Get basket data
 * @param {string} url Braintree-GetOrderInfo URL
 * @returns {Object} Object with basket data from DW
 */
function getBasketData(url) {
    return $.ajax({
        url: url,
        method: 'GET',
        async: false
    }).responseJSON;
}

/**
 * Submit payment (billing) data to the server
 * @param {string} submitPaymentUrl Submit payment URL
 * @param {FromData} billingFormData Billing Form Data
 * @param {string} redirectUrl Redirect URL after succesfull payment data submit
 * @param {Object} loader Loader
 * @param {Object} ErrorModelInstance Error Model instance
 * @returns {Promise} Promise with response result
 */
function submitPaymentDataToServerPromise(submitPaymentUrl, billingFormData, redirectUrl, loader, ErrorModelInstance) {
    return $.ajax({
        type: 'POST',
        url: submitPaymentUrl,
        data: billingFormData,
        contentType: false,
        processData: false
    })
    .done(function (data) {
        if (data.error) {
            var errorMessage = '';

            if (data.fieldErrors.length) {
                data.fieldErrors.forEach(function (error, index) {
                    var keys = Object.keys(error);
                    if (keys.length) {
                        errorMessage += `${keys[index].replace('dwfrm_billing_', '').replace('_', ' ')} ${data.fieldErrors[index][keys[index]]}. `;
                    }
                });
                ErrorModelInstance.showErrorByMessage(errorMessage);
            }

            if (data.serverErrors.length) {
                data.serverErrors.forEach(function (error) {
                    errorMessage += `${error}. `;
                });
                ErrorModelInstance.showErrorByMessage(errorMessage);
            }

            // Usually in case of any errors "cartError" will be "true"
            if (data.cartError) {
                window.location.href = data.redirectUrl;
            }
            loader.hide();
        } else {
            var expressPayPalCheckShipping = $('#expressPayPalCheckShipping').val();
            $.ajax({
                type: 'GET',
                url: expressPayPalCheckShipping
            })
            .done(function (response) {
                if (response && response.redirectUrl !== '') {
                    sessionStorage.setItem('pageState', 'cart');
                    window.location.href = response.redirectUrl;
                    loader.hide();
                } else {
                    sessionStorage.setItem('pageState', 'cart');
                    window.location.href = redirectUrl;
                    loader.hide();
                }
            })
            .fail(function () {
                sessionStorage.setItem('pageState', 'cart');
                window.location.href = redirectUrl;
                loader.hide();
            });
        }
    })
    .fail(function (err) {
        loader.hide();
        if (err && err.redirectUrl) {
            window.location.href = err.redirectUrl;
        }
    });
}

module.exports = {
    addProductToCart,
    getBasketDataPromise,
    getBasketData,
    submitPaymentDataToServerPromise
};
