'use strict';

var PayPalBaseModel = require('./payPalBaseModel');
var PayPalExpressModel = require('./payPalExpressModel');
var payPalProductPageModelHelper = require('../helpers/payPalProductPageModelHelper');
var payPalExpressModelHelper = require('../helpers/payPalExpressModelHelper');
var requestsHelper = require('../helpers/payPalRequestsHelperGlobal');

/**
 * PayPal product model constructor
 * @param {Object} errorHandlingModel error handling model
 * @param {Promise} btClientInstancePromise BT client instance promise
 * @param {string} payPalButtonSelector PayPal button selector
 * @param {Object} payPalConfigurations configs from server (taken from data attr)
 * @param {boolean} isVaultMode "true" in case if vault mode is enabled
 * @param {boolean} requestBillingAgreement "true" in case if billing agreement should be requested
 * @param {boolean} isFraudToolsEnabled "true" in case if fraud tools is enabled
 * @param {Object} $csrfToken CSRF token js container
 * @param {Object} $loaderContainer loader js container
 */
function PayPalProductPageModel(
    errorHandlingModel,
    btClientInstancePromise,
    payPalButtonSelector,
    payPalConfigurations,
    isVaultMode,
    requestBillingAgreement,
    isFraudToolsEnabled,
    $csrfToken,
    $loaderContainer
) {
    // Init PayPalExpressModel constructor
    PayPalExpressModel.call(
        this,
        errorHandlingModel,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        isVaultMode,
        requestBillingAgreement,
        isFraudToolsEnabled,
        $csrfToken,
        $loaderContainer
    );

    this.changePMButtonEnabled = payPalConfigurations.changePMButtonEnabled;
}

// ES5 inheritance
PayPalProductPageModel.prototype = Object.create(PayPalExpressModel.prototype);

// Dummy function to override default behavior and do not execute "GetOrderInfo" call.
// Instead, we will get basket data inside "onOrderCreateCallback"
PayPalProductPageModel.prototype.onPayPalButtonClickCallback = function (e) {
    PayPalBaseModel.prototype.onPayPalButtonClickCallback.call(this, e);
};

// Add product to the Cart
PayPalProductPageModel.prototype.onOrderCreateCallback = function () {
    var orderData;
    var addProductToCartResponse = requestsHelper.addProductToCart();
    var basketDataUrl = this.basketDataUrl;
    var isUseSavedPaypalAddress;

    this.basketData = requestsHelper.getBasketData(basketDataUrl);
    this.addedProductFromPdp = {
        uuid: addProductToCartResponse.pliUUID,
        pid: addProductToCartResponse.pid
    };
    this.removeFromCartUrl = addProductToCartResponse.cart.actionUrls.removeProductLineItemUrl;

    isUseSavedPaypalAddress = this.changePMButtonEnabled && !this.basketData.shippingAddress;

    if (isUseSavedPaypalAddress) {
        this.savedPaypalAddress = payPalExpressModelHelper.getSavedPaypalAddress(this.payPalButtonSelector);
    }

    orderData = PayPalBaseModel.prototype.onOrderCreateCallback.call(this);

    return orderData;
};

// On error remove added product from Cart
PayPalProductPageModel.prototype.onErrorPaymentCallback = function (error) {
    PayPalExpressModel.prototype.onErrorPaymentCallback.call(this, error);

    payPalProductPageModelHelper.removeProductFromCart(this.removeFromCartUrl, this.addedProductFromPdp);
};

// On cancel remove added product from Cart
PayPalProductPageModel.prototype.onCancelPaymentCallback = function () {
    payPalProductPageModelHelper.removeProductFromCart(this.removeFromCartUrl, this.addedProductFromPdp);
};

module.exports = PayPalProductPageModel;
