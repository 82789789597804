'use strict';

var ErrorHandlingBaseModel = require('./errorHandlingBaseModel');

/**
 * Error Handling MiniCart model constructor
 * @param {Object} errorMessages object with error messages
 */
function ErrorHandlingMiniCartModel(errorMessages) {
    ErrorHandlingBaseModel.call(this, errorMessages);

    this.$errorContainer = $('.minicart-error');
    this.errorMessages = errorMessages;
}

/**
 * ES5 inheritance
 */
ErrorHandlingMiniCartModel.prototype = Object.create(ErrorHandlingBaseModel.prototype);

// See corresponding method inside "ErrorHandlingBaseModel" model
ErrorHandlingMiniCartModel.prototype.createErrorNotification = function (message) {
    this.$errorContainer.append(
        `<div class="alert alert-danger alert-dismissible valid-cart-error fade show cartError custom-cart-error" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            ${message}
        </div>`
    );

    this.$errorContainer.show();
};

// See corresponding method inside "ErrorHandlingBaseModel" model
ErrorHandlingMiniCartModel.prototype.hideError = function () {
    this.$errorContainer.hide();
    this.$errorContainer.empty();
};

module.exports = ErrorHandlingMiniCartModel;
