'use strict';

/**
* This file was created to provide functionality and business logic for Credit and SRC payment methods. Do not expand this file!
* Changes and additions to the code should be made to the appropriate files in the folders, depending on the payment method required, to maintain a clear code architecture.
*/

var loaderInstance = require('./loaderHelper');
var ErrorHandlingBaseModel = require('./creditcard/errorhandler/errorHandlingBaseModel');

var errorHandlingBaseModel = new ErrorHandlingBaseModel();
var creditCardListLoader;

/**
* Shows the new card block on the Account Page
*/
function showAddNewCardOptions() {
    var $addNewCardButton = document.querySelector('.braintreeAddNewCard');
    var $addNewCardBlock = document.querySelector('.braintreeAddNewCardBlock');

    // Hide "Credit Card error message" container
    errorHandlingBaseModel.hideError();
    // Hide "Add New Card" button
    $addNewCardButton.style.display = 'none';
    // Display "Add New Card" form
    $addNewCardBlock.style.display = 'block';
}

/**
 * Removes card from Credit/SRC Cards list
 * @param {Object} e Event object
 */
function removeCard(e) {
    var target = e.target;

    if (target && JSON.parse(target.getAttribute('data-is-remove-allowed'))) {
        creditCardListLoader.show();

        $.get(target.getAttribute('data-url') + '?UUID=' + target.getAttribute('data-id'))
            .then((data => {
                $('#uuid-' + data.UUID).remove();

                if (data.newDefaultAccount) {
                    document.querySelector('#uuid-' + data.newDefaultAccount + ' span').style.fontWeight = 'bold';
                    document.querySelector('#uuid-' + data.newDefaultAccount + ' button.braintree-make-default-card').style.display = 'none';
                }

                creditCardListLoader.hide();
            }))
            .fail(() => {
                location.reload();
            });
    } else {
        var removeErrorMessage = target.getAttribute('data-message-remove-is-not-allowed');
        errorHandlingBaseModel.showErrorByMessage(removeErrorMessage);
    }
}

/**
 * Select default card in Credit/SRC Cards list
 * @param {Object} e Event object
 */
function makeDefaultCard(e) {
    var target = e.target;
    var url = target.getAttribute('data-make-default-url');
    var id = target.getAttribute('data-id');
    var paymentMethodID = 'CARD';

    // Hide "Credit Card error message" container
    errorHandlingBaseModel.hideError();
    creditCardListLoader.show();

    $.get(url + '?UUID=' + id + '&pmID=' + paymentMethodID)
        .then((data) => {
            // New default Card changes
            document.querySelector('#uuid-' + data.newDefaultProperty + ' span').style.fontWeight = 'bold';
            document.querySelector('.braintree-make-default-card.uuid-' + data.newDefaultProperty).style.display = 'none';

            // Previous default Card changes
            document.querySelector('#uuid-' + data.toRemoveDefaultProperty + ' span').style.fontWeight = 'normal';
            document.querySelector('.braintree-make-default-card.uuid-' + data.toRemoveDefaultProperty).style.display = 'inline';

            creditCardListLoader.hide();
        })
        .catch(function () {
            creditCardListLoader.hide();
        });
}

function initAccount() {
    var $creditCardAccountBtLoader = document.querySelector('#creditCardAccountBtLoader');
    var creditCardListLoaderExists = Boolean($creditCardAccountBtLoader);

    if (creditCardListLoaderExists) {
        creditCardListLoader = loaderInstance($creditCardAccountBtLoader);
    }
    // "Add new card" area link functionality initiation
    $('.braintreeAddNewCard').click(showAddNewCardOptions);

    // "Make default" link functionality initiation
    $('.creditCard-accounts').on('click', '.braintree-make-default-card', makeDefaultCard);

    // "Remove Card" link functionality initiation
    $('.creditCard-accounts').on('click', '.remove-bt-payment', removeCard);
}

module.exports = {
    initAccount
};
